//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from "jquery";
import { mapActions, mapState } from 'vuex';

import api from '@/api/api';
import Loader from '@/components/main/Loader'
import Modal from "@/components/modal/Modal";
import notification from "@/notification/notification";

export default {
  name: 'Card',
  components: {
    Modal,
    Loader
  },
  data() {
    return {
      snilsModalShow: false,
      snils: this.worker.snils || '',
      snilsState: null,
      snilsPending: false,
      regExpSnils: /((\d){3}-){2}(\d){3}\s\d\d/
    }
  },
  props: {
    worker: Object,
    history: Array,
    isMobile: Boolean,
  },
  methods: {
    showHistory() {
      $('#modalCadastre' + (this.isMobile ? '_mobile' : '')).modal('show')
    },
    hideHistory() {
      $('#modalCadastre' + (this.isMobile ? '_mobile' : '')).modal('hide')
    },
    toggleSnilsModal() {
      this.snilsModalShow = !this.snilsModalShow;
    },
    async saveSnils() {
      this.snilsPending = true;
      let snilsResponse = null;

      try {
        snilsResponse = await api.updateStudent({
          UF_SNILS: this.snils || null,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.snilsPending = false;
      }

      if (!(Number.isInteger(snilsResponse.data.error)) && snilsResponse.data.result && snilsResponse.data.result.result) {
        notification.information(this.$t('sidebar.save_snils_success'));
        this.getWorker();       
        this.snilsModalShow = false;
      } else {
        this.snilsState = false;
      }
    },
    snilsInput(event) {
      if (this.snilsState !== null) this.snilsState = null;
      if (event.length < 14) return;
      this.snilsState = this.regExpSnils.test(this.snils)
    },
    snilsFormatter(value) {
      return (/[A-Z]/i.test(value)) ? this.snils : value;
    },
    clearSnils() {
      this.snils = '';
    },
    ...mapActions(['getWorker']),
  },
  computed: {
    canSave() {
      return !this.snilsPending && (this.snilsState || !this.snils.length);
    },
    ...mapState(['isSnilsEditable'])
  }
}
